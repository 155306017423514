<template>
  <section class="container pb-8">
    <div class="bg-light p-6 p-md-8 edit">
      <p class="px-md-6" v-html="noviceContent"></p>
    </div>
  </section>
</template>

<script>
import { GetNovicesByMode } from '@/api/Novices';

export default {
  data() {
    return {
      type: [
        { modelId: 1, modelName: '服務條款', modelPath: 'policy' },
        { modelId: 2, modelName: '購買須知', modelPath: 'buyNote' },
        { modelId: 3, modelName: '關於我們', modelPath: 'about' },
        { modelId: 4, modelName: '班別介紹', modelPath: 'class' },
        { modelId: 5, modelName: '購買流程', modelPath: 'buyProcess' },
        { modelId: 6, modelName: '聯絡我們', modelPath: 'contact' },
      ],
      noviceContent: '',
    };
  },
  created() {
    this.GetData();
  },
  watch: {
    $route() {
      this.GetData();
    },
  },
  methods: {
    GetData() {
      const { type } = this.$route.params;
      const modelId = this._.filter(this.type, ['modelPath', type]);
      if (this._.isEmpty(modelId)) this.$router.replace('/');
      window.document.title = `讀家雲端學院-${modelId[0].modelName}`;

      this.$emit('update:currentPage', {
        name: '',
        title: modelId[0].modelName,
        path: '',
      });
      GetNovicesByMode(modelId[0].modelId).then((response) => {
        if (response.data.statusCode === 0) {
          this.noviceContent = response.data.data.noviceContent;
        }
      });
    },
  },
};
</script>

<style></style>
